import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'sweetalert2/src/sweetalert2.scss'
import Toaster from "@meforma/vue-toaster";
import { help } from '@/utils/gestionNiveaux';
createApp(App).use(store).use(router).use(Toaster).mount('#app')

// Vérifier que l'utilisateur est bien connecté et le rediriger
// sur la page de connexion si ce n'est pas le cas
if (localStorage.getItem("user") === null) {
  console.log('Utilisateur déconnecté')
  router.push({path: '/'})
} else {
  console.log('Utilisateur connecté')
}
