import { createRouter, createMemoryHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'login',
    // meta: {
    //   auth: true,
    // },
    component: () => import('../views/PageLogin.vue'),
  },
  {
    path: '/etape-1',
    name: 'etape1',
    component: () => import('../views/PageEtape1.vue'),
  },
  {
    path: '/etape-2',
    name: 'etape2',
    component: () => import('../views/PageEtape2.vue'),
  },
  {
    path: '/etape-3',
    name: 'etape3',
    component: () => import('../views/PageEtape3.vue'),
  },
  {
    path: '/etape-4',
    name: 'etape4',
    component: () => import('../views/PageEtape4.vue'),
  },
  {
    path: '/etape-5',
    name: 'etape5',
    component: () => import('../views/PageEtape5.vue'),
  },
  {
    path: '/etape-6',
    name: 'etape6',
    component: () => import('../views/PageEtape6.vue'),
  },
  {
    path: '/fin',
    name: 'fin',
    component: () => import('../views/PageDeFin.vue'),
  },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if(to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }

  next()
})

export default router;
