import { createStore } from 'vuex'
import axios from "axios";
import {createToaster} from "@meforma/vue-toaster";
import router from "@/router";
import { recupData } from '@/components/MenuDeroulant';

axios.defaults.baseURL = "https://api-mars.divtec.ch/api/"

export default createStore({
  state: {
    user: null,
    // La liste est constituée de différents état
    // 0 : étape non commencée
    // 1 : erreur d'étape
    // 2 : étape réussite
    listLvl:[0,0,0,0,0,0],
    listLvl2:[false,false,false,false,false,false],
    listeRecompenseCollecte:[false,false,false,false,false,false],
    piece: 0,
    name: "",
    valeurSaisie: "",
    modeInvite: false,
    grille: ""
  },

  getters: {
    isLogged: state => !!state.user
  },


  mutations: {
    setPiece () {
      return this.store.piece;
    },

    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      location.reload()
    }
  },

  actions: {

    login ({commit}, credentials) {

      const toaster = createToaster({ position: "bottom" });

      return axios
        .post("/login", {
          email: credentials.email,
          password: credentials.password
        })
        .then(({data}) => {
          //toaster.success(`Bienvenue ${data.user.name} !`);
          router.push('/etape-1')
          toaster.success(`Bienvenue ${data.user.first_name} !`);
          commit('setUserData', data)
        })
        .catch(err => {
          if (err.response.status === 401) {
            toaster.error("Adresse mail ou mot de passe invalide !");
          }
        })

    },

    logout({commit}) {
      commit('clearUserData')
    },
  },


  modules: {
  }
})

