<template>
  <!-- Menu de navigation -->
    <ul class="menu">
      <li>
        <ul class="sub-menu">
          <li>
            <!-- Boutons de navigations entre les différents niveaux -->
            <router-link to="/etape-1"><button id="menu_btn1">1</button></router-link>
            <router-link to="/etape-2"><button id="menu_btn2">2</button></router-link>
            <router-link to="/etape-3"><button id="menu_btn3">3</button></router-link>
            <router-link to="/etape-4"><button id="menu_btn4">4</button></router-link>
            <router-link to="/etape-5"><button id="menu_btn5">5</button></router-link>
            <router-link to="/etape-6"><button id="menu_btn6">6</button></router-link>
          </li>
        </ul>
      </li>
    </ul>
</template>

<script>

export default {
  name: 'menuDeroulant'
};


</script>

<style scoped>

button {
  border: none;
  color: white;
  border-color: white;
  border-radius: 25px;
  text-decoration: none;
  padding: 10px 15px;
  margin: 3px;
  background-color: #CC5200;
  font-size: 14px;
}

button:hover {
  background-color: #803300;
}

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
}

.menu {
  display: flex;
  float: left;
  margin: 3% 0 0 4%;
}

@media only screen and (max-width: 1560px) {
  .menu {
    display: flex;
    float: left;
    margin: -2% 10% 3% 3%;
  }
}

</style>
