import router from '@/router';
import Swal from 'sweetalert2';
import store from '@/store';
import JSConfetti from 'js-confetti';

export function help() {
  Swal.fire({
    title: 'Tutoriel',
    color: '#fff',
    html:
      '<iframe width="1280" height="720" src="https://css-mars.divtec.ch/demo.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonColor: '#FF6600',
    confirmButtonText:
      "J'ai compris !",
    width: '1500px',
    background: 'url()',
    backdrop:
      'rgba(0,0,0,0.8)',
  })
    .then((result) => {
      if (result.value === true) {
        document.getElementById('code').focus()
      }
    });
}

/**
 * Démarre l'animation "bien joué"
 */
export function playAnimation() {
  const editor = document.getElementById('editor');
  editor.style.animationPlayState = 'running';
}

/**
 * Stop l'animation "bien joué"
 */
export function stopAnimation() {
  const editor = document.getElementById('editor');
  editor.style.animationPlayState = 'paused';
}

/**
 * Gestion des récompenses des niveaux avec l'api
 */
export function recompenseNiveau() {
  if (store.state.modeInvite !== true) {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    for (let i = 0; i <= 5; i++) {
      if (store.state.listLvl2[i] === true) {
        if (store.state.listeRecompenseCollecte[i] === false) {
          store.state.listeRecompenseCollecte[i] = true;
          fetch(`https://api-mars.divtec.ch/api/badges`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              store.state.piece += result[i].prix;
            })
        }
      }
    }
  }
}

/**
 * Colorie le background des boutons en fonction de l'état de l'étape (validée/non validée)
 */
export function couleurNiveau() {
  const listeFinishedLevels = store.state.listLvl;

  function niveauValide(id_bouton) {
    return document.getElementById(id_bouton).style.backgroundColor = '#45d91f';
  }

  function niveauNonValide(id_bouton) {
    return document.getElementById(id_bouton).style.backgroundColor = '#E22222';
  }

  for (let i = 0; i <= 5; i++) {
    if (listeFinishedLevels[i] === 2) {
      switch (i) {
        case 0:
          niveauValide('menu_btn1');
          break;
        case 1:
          niveauValide('menu_btn2');
          break;
        case 2:
          niveauValide('menu_btn3');
          break;
        case 3:
          niveauValide('menu_btn4');
          break;
        case 4:
          niveauValide('menu_btn5');
          break;
        case 5:
          niveauValide('menu_btn6');
          break;
      }
    } else if (listeFinishedLevels[i] === 1) {
      switch (i) {
        case 0:
          niveauNonValide('menu_btn1');
          break;
        case 1:
          niveauNonValide('menu_btn2');
          break;
        case 2:
          niveauNonValide('menu_btn3');
          break;
        case 3:
          niveauNonValide('menu_btn4');
          break;
        case 4:
          niveauNonValide('menu_btn5');
          break;
        case 5:
          niveauNonValide('menu_btn6');
          break;
      }
    }
  }
}

/**
 * Message d'erreur
 */
export function msgErreur(texte) {
  Swal.fire({
    icon: 'error',
    title: 'Erreur!',
    background: 'url()',
    color: '#efefef',
    backdrop:
      'rgba(0,0,0,0.7)',
    confirmButtonColor: '#ea5d5d',
    allowEnterKey: true,
    html: `Tu as fait une erreur ! ${texte}`,
  });
}

/**
 * Message de réussite
 */
export function msgSucces(texte) {
  Swal.fire({
    icon: 'success',
    title: 'Bravo!',
    text: texte,
    timer: 1500,
    background: 'url()',
    color: '#efefef',
    backdrop:
      'rgba(0,0,0,0.7)',
    timerProgressBar: true,
    showConfirmButton: false,
  });
}

/**
 * Fonction qui reset le textarea après le passage au niveau suivant
 */
export function resetTextArea() {
  const textarea = document.getElementById('code');
  if(etapeTerminee.toString() !== "true,true,true,true,true,true") {
    textarea.value = '';
  }
}


let etapeTerminee = [false,false,false,false,false,false];

/**
 * Vérification de la validation des étapes afin d'envoyer le joueur
 * à la page de fin lorsqu'il a terminé tous les niveaux
 * @param numEtape niveau en cours
 */
export function verifValidation(numEtape) {
    etapeTerminee[numEtape] = true;
  if (etapeTerminee.toString() === "true,true,true,true,true,true") {
    function envoyerFin() {
      router.push({ path: '/fin' })
    }
    setTimeout(envoyerFin, 2000)
  }
}


/**
 * Fonction appellée lors de la réussite d'un niveau
 */
export function niveauValide(val, etapeActuelle, etapeSuivante) {
  // Appel de la fonction d'envoi des données
  envoiDonnee();

  // Afficher les confettis
  const jsConfetti = new JSConfetti();
  jsConfetti.addConfetti();

  // Reset la position des rovers
  function resetPosition() {
    const rover = document.getElementById('pond');
    rover.style.cssText = 'justify-content:flex-start;';
  }

  // Passer à l'étape suivante
  function passerEtapeSuivante() {
    if (etapeSuivante === 7 && etapeTerminee.toString() !== "true,true,true,true,true,true") {
      // console.log(etapeTerminee.toString());
      router.push({ path:'etape-1' });
    } else if (etapeTerminee.toString() === "true,true,true,true,true,true") {
      router.push({ path: '/fin' });
      etapeTerminee = [false,false,false,false,false,false];
    } else {
      router.push({ path: `/etape-${etapeSuivante}` });
    }
  }

  // Envoyer les données à l'api
  function envoiDonnee() {
    if (!store.state.modeInvite === true) {

      const myHeaders = new Headers();

      myHeaders.append('Authorization', `Bearer ${JSON.parse(localStorage.user).access_token}`);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(`https://api-mars.divtec.ch/api/css/badges/${localStorage.etapeActuelle}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error));
    }
  }

  msgSucces('Tu as réussi le niveau !'); // Afficher le message de réussite

  store.state.listLvl2[etapeActuelle] = true;

  // change l'état de l'étape a "validé" dans le magasin
  store.state.listLvl[etapeActuelle] = 2;

  setTimeout(passerEtapeSuivante, 3600); // Passer à l'étape suivante
  setTimeout(resetTextArea, 3600); // Reset le champ de saisie
  setTimeout(resetPosition,3600); // Reset la postion des astronautes

  recompenseNiveau()
}

/**
 * Fonction appellée lors de l'échec d'un niveau
 */
export function niveauinvalide(val, etapeActuelle, reponse) {
  /// change l'état de l'étape a "non-validé" dans le magasin
  store.state.listLvl[etapeActuelle] = 1;

  // Vérification de la saisie de l'utilisateur
  if (etapeActuelle < 3 && !val.includes('align-ligne')) {
    msgErreur("Tu dois d'abord saisir la propriété <code>align-ligne: VALEUR;</code> ⚠️")
  } else if (etapeActuelle === 3 && !val.includes('align-colonne')) {
    msgErreur("Tu dois d'abord saisir la propriété <code>align-colonne: VALEUR;</code> ⚠️")
  } else if (etapeActuelle >= 4 && !val.includes('align-colonne') && !val.includes('align-ligne')) {
    msgErreur("Tu dois d'abord saisir les propriétés suivantes : ⚠️ <br><code>align-ligne: VALEUR;</code><br><code>align-colonne: VALEUR;</code>")
  } else if (etapeActuelle >= 4 && val.includes('align-ligne') && !val.includes('align-colonne')) {
    msgErreur("Tu dois saisir la 2ème propriété : ⚠️ <br><code>align-colonne: VALEUR;</code>")
  } else if (etapeActuelle >= 4 && !val.includes('align-ligne') && val.includes('align-colonne')) {
    msgErreur("Tu dois saisir la 2ème propriété : ⚠️ <br><code>align-ligne: VALEUR;</code>")
  } else if (etapeActuelle <= 3 && val.includes("gauche")) {
    msgErreur('La valeur <code>gauche</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("droite")) {
    msgErreur('La valeur <code>droite</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("centre")) {
    msgErreur('La valeur <code>centre</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("espace-entre")) {
    msgErreur('La valeur <code>espace-entre</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("espace-autour")) {
    msgErreur('La valeur <code>espace-autour</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("haut")) {
    msgErreur('La valeur <code>haut</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("bas")) {
    msgErreur('La valeur <code>bas</code> est incorrecte ❌');
  } else if (etapeActuelle <= 3 && val.includes("étiré")) {
    msgErreur('La valeur <code>étiré</code> est incorrecte ❌');
  }
  // Pour niveau 5
  else if (etapeActuelle === 4 && !val.includes("droite") && !val.includes("bas")) {
    msgErreur('La valeur de <code>align-ligne</code> et celle de <code>align-colonne</code> est incorrecte ❌');
  }
  else if (etapeActuelle === 4 && !val.includes("droite")) {
    msgErreur('La valeur de <code>align-ligne</code> est incorrecte ❌');
  }
  else if (etapeActuelle === 4 && !val.includes("bas")) {
    msgErreur('La valeur de <code>align-colonne</code> est incorrecte ❌');
  }

  // Pour niveau 6
  else if (etapeActuelle === 5 && !val.includes("align-ligne: centre") && !val.includes("align-colonne: centre")) {
    msgErreur('La valeur de <code>align-ligne</code> et celle de <code>align-colonne</code> est incorrecte ❌');
  }
  else if (etapeActuelle === 5 && !val.includes("align-ligne: centre")) {
    msgErreur('La valeur de <code>align-ligne</code> est incorrecte ❌');
  }
  else if (etapeActuelle === 5 && !val.includes("align-colonne: centre")) {
    msgErreur('La valeur de <code>align-colonne</code> est incorrecte ❌');
  }

  else if (etapeActuelle <= 2 && val === "align-ligne" || val === "align-ligne:") {
    msgErreur('Tu dois saisir une des valeurs disponibles ⚠️');
  } else if (etapeActuelle === 3 && val === "align-colonne" || val === "align-colonne:") {
    msgErreur('Tu dois saisir une des valeurs disponibles ⚠️');
  } else if (val !== reponse) {
    msgErreur("<b>La valeur saisie n'existe pas</b>  ‼️");
  }

  playAnimation(); // Démarrer l'animation
  setTimeout(stopAnimation, 1100); // Arrêter l'animation après 1.1 sec
}

/**
 * Fonction qui gère le pseudo langage
 */
export function pseudolangage(val) {
  const rover = document.getElementById('pond');

  // Pseudo langage pour la propriété JUSTIFY-CONTENT
  const justifyGauche = val.includes('align-ligne:gauche') || val.includes('align-ligne: gauche');
  const justifyDroite = val.includes('align-ligne:droite') || val.includes('align-ligne: droite');
  const justifyCentre = val.includes('align-ligne:centre') || val.includes('align-ligne: centre');
  const justifyEspaceEntre = val.includes('align-ligne:espace-entre') || val.includes('align-ligne: espace-entre');
  const justifyEspaceAutour = val.includes('align-ligne:espace-autours') || val.includes('align-ligne: espace-autours');

  // Pseudo langage pour la propriété ALIGN-ITEMS
  const alignHaut = val.includes('align-colonne:haut') || val.includes('align-colonne: haut');
  const alignBas = val.includes('align-colonne:bas') || val.includes('align-colonne: bas');
  const alignCentre = val.includes('align-colonne:centre') || val.includes('align-colonne: centre');
  const alignEtire = val.includes('align-colonne:étiré') || val.includes('align-colonne:étiré');

  // Tests pour appliquer le vrai CSS par rapport au pseudo langage
  if (alignHaut && justifyGauche) {
    rover.style.cssText = 'align-items:flex-start;justify-content:flex-start;';
  } else if (alignHaut && justifyDroite) {
    rover.style.cssText = 'align-items:flex-start;justify-content:flex-end;';
  } else if (alignHaut && justifyCentre) {
    rover.style.cssText = 'align-items:flex-start;justify-content:flex-end;';
  } else if (alignHaut && justifyEspaceEntre) {
    rover.style.cssText = 'align-items:flex-start;justify-content:space-between;';
  } else if (alignHaut && justifyEspaceAutour) {
    rover.style.cssText = 'align-items:flex-start;justify-content:space-around;';
  } else if (alignBas && justifyGauche) {
    rover.style.cssText = 'align-items:flex-end;justify-content:flex-start;';
  } else if (alignBas && justifyDroite) {
    rover.style.cssText = 'align-items:flex-end;justify-content:flex-end;';
  } else if (alignBas && justifyCentre) {
    rover.style.cssText = 'align-items:flex-end;justify-content:center;';
  } else if (alignBas && justifyEspaceEntre) {
    rover.style.cssText = 'align-items:flex-end;justify-content:space-between;';
  } else if (alignBas && justifyEspaceAutour) {
    rover.style.cssText = 'align-items:flex-end;justify-content:space-around;';
  } else if (alignCentre && justifyGauche) {
    rover.style.cssText = 'align-items:center;justify-content:flex-start;';
  } else if (alignCentre && justifyDroite) {
    rover.style.cssText = 'align-items:center;justify-content:flex-end;';
  } else if (alignCentre && justifyCentre) {
    rover.style.cssText = 'align-items:center;justify-content:center;';
  } else if (alignCentre && justifyEspaceEntre) {
    rover.style.cssText = 'align-items:center;justify-content:space-between;';
  } else if (alignCentre && justifyEspaceAutour) {
    rover.style.cssText = 'align-items:center;justify-content:space-around;';
  } else if (alignEtire && justifyGauche) {
    rover.style.cssText = 'align-items:stretch;justify-content:flex-start;';
  } else if (alignEtire && justifyDroite) {
    rover.style.cssText = 'align-items:stretch;justify-content:flex-end;';
  } else if (alignEtire && justifyCentre) {
    rover.style.cssText = 'align-items:stretch;justify-content:flex-end;';
  } else if (alignEtire && justifyEspaceEntre) {
    rover.style.cssText = 'align-items:stretch;justify-content:space-between;';
  } else if (alignEtire && justifyEspaceAutour) {
    rover.style.cssText = 'align-items:stretch;justify-content:space-around;';
  } else if (justifyCentre) {
    rover.style.cssText = 'justify-content:center;';
  } else if (justifyGauche) {
    rover.style.cssText = 'justify-content:flex-start;';
  } else if (justifyDroite) {
    rover.style.cssText = 'justify-content:flex-end;';
  } else if (justifyEspaceEntre) {
    rover.style.cssText = 'justify-content:space-between;';
  } else if (justifyEspaceAutour) {
    rover.style.cssText = 'justify-content:space-around;';
  } else if (alignCentre) {
    rover.style.cssText = 'align-items:center;';
  } else if (alignHaut) {
    rover.style.cssText = 'align-items:flex-start;';
  } else if (alignBas) {
    rover.style.cssText = 'align-items:flex-end;';
  } else if (alignEtire) {
    rover.style.cssText = 'align-items:stretch;';
  } else {
    rover.style.cssText = val;
  }
}

/**
 * Fonction qui met en évidence dans quel niveau on se trouve
 */
export function niveauActuel(etapeActuelle) {
  if (etapeActuelle !== 0) {
    document.getElementById(`menu_btn${etapeActuelle}`).style.border = 'none';
  }
  document.getElementById(`menu_btn${etapeActuelle + 1}`).style.border = '2px solid #fff';
}

export function etapeActuelle(etapeActuelle) {
  localStorage.etapeActuelle = etapeActuelle;
}

/**
 * Fonction qui gère le nombre de ligne pour les niveaux en ayant 2
 * @param etapeActuelle niveau actuel
 */
export function nbreLigne(etapeActuelle) {

  let textarea = document.getElementById('code');

  if (etapeActuelle === 5 || etapeActuelle === 6) {
    // Aggrandir le textarea pour deux lignes
    document.getElementById('code').style.height = '48px';

    let ligne = 0;

    // Ecouter la touche DELETE jusqu'à voir quand la 2ème ligne
    // est effacée afin de réactiver la touche enter
    textarea.addEventListener("keyup", function(event) {
      if (event.key === "Backspace") {
        if (!textarea.value.includes('\n')) {
          ligne = 0;
        }
      }
    });

    // Ecouter la touche ENTER afin de limiter son nombre d'utilisation
    textarea.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        ligne++;
        if (ligne >=2) {
          event.preventDefault();
        }
      }
    });
  } else {
    // Désactiver la touche ENTER pour les niveaux 1 à 4
    textarea.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
          event.preventDefault();
        }
    });
  }
}

